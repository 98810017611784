import React, { useContext } from "react";

import * as actions from "../action";
import { connect } from "react-redux";
import "moment/locale/es";

import PrefixContext from "../context/PrefixContext";
import DomainContext from "../context/DomainContext";
import "./Global.scss";

import Home from "./home/";
import Pro from "./pro/";
import Marktdaten from "./marktdaten";

class IndexPage extends React.Component {
  constructor(props) {
    super(props);
  }

  setElementsLoad(val) {
    this.setState({ elementsLoaded: val });
  }

  render() {
    const { domain, prefix } = this.props;
    return domain != "ch" ? (
      prefix === "/marktdaten" ? (
        <Marktdaten />
      ) : (
        <Pro />
      )
    ) : (
      <Home />
    );
  }
}

function mapStateToProps({ leadForm, products }) {
  return {};
}
export function IndexWithContext(props) {
  const domain = useContext(DomainContext);
  const prefix = useContext(PrefixContext);
  return <IndexPage {...props} domain={domain} prefix={prefix} />;
}

export default connect(mapStateToProps, actions)(IndexWithContext);
